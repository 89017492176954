import Billboard from 'scripts/components/Billboard';
import EpisodeResourceList from 'scripts/components/EpisodeResourceList';
import EventList from 'scripts/components/EventList';
import Tags from 'scripts/components/Tags';
import Page from './Page';

export default class Homepage extends Page {
  constructor() {
    super(...arguments);

    new Billboard(this.element);
    new Tags(this.element);
    new EpisodeResourceList(this.element);
    new EventList(this.element);
  }
}
