import 'styles/components/_billboard.scss';
import Component from 'scripts/components/component.js';
import Swiper from 'swiper';
import { Navigation, Pagination, A11y, EffectFade } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

export default class Billboard extends Component {
	constructor(element) {
		super(...arguments);

		if(!this.element) {
			return;
		}

    this.elements.swiper = this.queryElement('swiper');
    this.elements.prev = this.queryElement('prev');
    this.elements.next = this.queryElement('next');
    this.elements.pagination = this.queryElement('pagination');

    this.swiper = new Swiper(this.elements.swiper, {
      modules: [Navigation, Pagination, A11y, EffectFade],
      effect: 'fade',
      crossFade: true,
      loop: true,
      fadeEffect: {
        crossFade: true
      },
      navigation: {
        prevEl: this.elements.prev,
        nextEl: this.elements.next,
      },
      pagination: {
        el: this.elements.pagination,
        clickable: true,
      },
      a11y: {
        enabled: true,
      }
    });
	}
}
